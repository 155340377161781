import React, { useState, useEffect } from 'react';
import Home from './Home';
import Books from './Books';
import User from './User';
import FooterNav from '../components/FooterNav';
import getCheckIfBookPurchased from '../api/get/getCheckIfBookPurchased';
import HeaderDesktop from '../components/HeaderDesktop';

function Main() {
    const [activeTab, setActiveTab] = useState('home');
    const [bookId, setBookId] = useState(null);

    useEffect(() => {
        const savedTab = localStorage.getItem('activeTab');
        if (savedTab) {
            setActiveTab(savedTab);
        }
    }, []);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        localStorage.setItem('activeTab', tab);
    };

    const onPlay = async (bookId, isPaid) => {
        const hasAccess = await getCheckIfBookPurchased(bookId);
        if (!isPaid || hasAccess) {
            setBookId(bookId);
        } else {
            alert('Қолжетімділік жоқ');
        }
    };

    return (
        <div>
            {activeTab === 'home' && <HeaderDesktop activeTab={activeTab} handleTabChange={handleTabChange} bookId={bookId} />}
            {activeTab === 'home' && <Home onPlay={onPlay} />}
            {activeTab === 'search' && <Books />}
            {activeTab === 'library' && <User />}
            <FooterNav activeTab={activeTab} handleTabChange={handleTabChange} bookId={bookId} />
        </div>
    );
}

export default Main;