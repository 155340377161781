const API_URL = process.env.REACT_APP_API_URL_PROD;

const getGenres = async () => {
    try {
        const token = localStorage.getItem("access_token_esti");

        const response = await fetch(`${API_URL}/genres/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token ? `Bearer ${token}` : "",
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch books:", error);
        return null;
    }
};

export default getGenres;