import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../config";
import { createUserWithEmailAndPassword } from "@firebase/auth";
import app_logo from '../icons/app_logo.svg';

const Register = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(null);

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            navigate("/home");
        } catch (err) {
            switch (err.code) {
                case "auth/email-already-in-use":
                    setErrorMessage("Бұл электрондық пошта тіркелген.");
                    break;
                case "auth/weak-password":
                    setErrorMessage("Құпия сөз тым әлсіз. Кемінде 6 таңба болуы керек.");
                    break;
                case "auth/invalid-email":
                    setErrorMessage("Электрондық пошта дұрыс емес.");
                    break;
                default:
                    setErrorMessage("Қате орын алды. Қайталап көріңіз.");
            }
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-white">
            <div className="w-full max-w-md p-8">
                <div className="flex justify-center mb-6">
                    <img src={app_logo} alt="app_logo" />
                </div>

                <h2 className="text-2xl font-bold text-center text-gray-800">Тіркелу</h2>

                {errorMessage && <p className="text-red-500 text-center mt-2">{errorMessage}</p>}

                <form onSubmit={handleSubmit} className="mt-6">
                    <input
                        type="email"
                        placeholder="Электрондық пошта"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-3 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#3C78F9] mb-4"
                        required
                        autoComplete="username"
                    />
                    <input
                        type="password"
                        placeholder="Құпия сөз"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-3 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#3C78F9] mb-4"
                        required
                        autoComplete="new-password"
                    />

                    <p className="text-sm text-center text-gray-600 mt-4">
                        Тіркелу арқылы сіз біздің <span className="text-[#E68F5A] font-bold">Шарттарымызға</span>, <span className="text-[#E68F5A] font-bold">Деректер саясатына</span> және <span className="text-[#E68F5A] font-bold">Cookie саясатына</span> келісесіз.
                    </p>

                    <button type="submit" className="w-full mt-6 bg-[#3C78F9] text-white p-3 rounded-lg font-bold hover:bg-[#2A5ECB]">
                        Тіркелу
                    </button>
                </form>

                <button className="w-full mt-3 border-2 border-[#3C78F9] text-[#3C78F9] p-3 rounded-lg font-bold hover:bg-[#3C78F9] hover:text-white">
                    <a href="/login">Бас тарту</a>
                </button>
            </div>
        </div>
    );
};

export default Register;