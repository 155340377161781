import React from "react";
import Header from "../components/Header";
import CategoriesSection from "../sections/CategoriesSection";
import RecommendedSection from "../sections/RecommendedSection";
import BestSellerSection from "../sections/BestSellerSection";

const HomeUI = ({ onPlay, onBookAccessDenied }) => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <div className="flex-grow space-y-6">
                <CategoriesSection />
                <RecommendedSection onPlay={onPlay} onBookAccessDenied={onBookAccessDenied} />
                <BestSellerSection onPlay={onPlay} />
            </div>
        </div>
    );
};

export default HomeUI;