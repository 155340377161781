import React, { useState, useEffect } from 'react';
import '../styles/pay_modal.css';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../config';

import postPayment from '../api/post/postPayment';

const BuyModal = ({ isOpen, bookId, onClose }) => {
    const [bookData, setBookData] = useState(null);
    const [paymentResponse, setPaymentResponse] = useState('');
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [agreeCard, setAgreeCard] = useState(false);

    useEffect(() => {
        const fetchBookData = async () => {
            if (!bookId) return;
            try {
                const bookRef = doc(db, 'books', bookId);
                const bookSnap = await getDoc(bookRef);
                if (bookSnap.exists()) {
                    setBookData(bookSnap.data());
                } else {
                    console.error('Кітап табылмады.');
                }
            } catch (error) {
                console.error('Кітап деректерін жүктеу қатесі:', error);
            }
        };
        fetchBookData();
    }, [bookId]);

    const handleBuyByCard = async (e) => {
        e.preventDefault();
        setPaymentResponse('');

        if (!bookData) {
            alert('Кітап деректері жүктелмеген.');
            return;
        }

        try {
            const user = auth.currentUser;
            if (!user) {
                throw new Error('Пайдаланушы анықталмады.');
            }

            const response = await postPayment(bookId, 30);
            setPaymentResponse(response.response);
            setIsPaymentModalOpen(true);
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('Төлем өңдеу кезінде қате пайда болды. Қайта байқап көріңіз.');
        }
    };

    if (!isOpen || !bookData) return null;

    return (
        <div className="pay_modal">
            <button
                onClick={onClose}
                className="fixed top-2 right-2 text-gray-600 hover:text-gray-800 bg-gray-200 hover:bg-gray-300 rounded-full p-2 transition duration-300 w-12 h-12"
            >
                ✕
            </button>
            <div className="pay_modal_content">
                <form onSubmit={handleBuyByCard} className="pay_modal_overlay">
                    <p className="pay_modal_text">{bookData.name}</p>
                    <p className="pay_modal_text">Құны: {bookData.price} ₸</p>
                    <p className="pay_modal_text">Сипаттама: {bookData.description}</p>
                    <div className="pay_check_content">
                        <p className="pay_text">
                            Тіркеу арқылы сіз біздің{' '}
                            <a className="link" target='_blank' href='https://firebasestorage.googleapis.com/v0/b/tynda-65659.appspot.com/o/%D0%BA%D0%B0%D0%B7_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8_%D0%B4%D0%BB%D1%8F_%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BD%D0%B0_%D1%81%D0%B0%D0%B9%D1%82%D0%B5.pdf?alt=media&token=50c33b96-fb75-4b68-9d9d-222be9db7fa6' rel="noreferrer">төлем</a> шарттармен,{' '}
                            <a className='link' href='https://firebasestorage.googleapis.com/v0/b/tynda-65659.appspot.com/o/%D0%BA%D0%B0%D0%B7_%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf?alt=media&token=1ad2f376-66f5-45e8-8440-d08c4a9befee' target='_blank' rel="noreferrer">құпиялылық саясатымен</a> және cookie файлдары саясатымен келісесіз.
                        </p>
                        <div className="pay_checkbox_container">
                            <input
                                type="checkbox"
                                id="agree"
                                checked={agreeCard}
                                onChange={() => setAgreeCard(!agreeCard)}
                            />
                            <label htmlFor="agree">
                                <svg className="pay_check_icon" viewBox="0 0 24 24">
                                    <path d="M20.285,6.708l-11.4,11.4a1.5,1.5,0,0,1-2.121,0l-5.4-5.4a1.5,1.5,0,0,1,2.121-2.121L7.5,15.185,18.164,4.523a1.5,1.5,0,0,1,2.121,2.185Z" fill="currentColor" />
                                </svg>
                            </label>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className={`save_button ${!agreeCard ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700'}`}
                        disabled={!agreeCard}
                    >
                        Сатып алу
                    </button>
                    {isPaymentModalOpen && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <div className="relative bg-white w-full max-w-2xl h-full md:h-auto rounded-lg shadow-lg p-6">
                                <button
                                    onClick={() => setIsPaymentModalOpen(false)}
                                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 bg-gray-200 hover:bg-gray-300 rounded-full p-2 transition duration-300 w-10 h-10 flex items-center justify-center"
                                >
                                    ✕
                                </button>
                                <div className="overflow-y-auto max-h-[90vh] p-4">
                                    <div dangerouslySetInnerHTML={{ __html: paymentResponse }} />
                                </div>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default BuyModal;