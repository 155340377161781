import React from "react";
import app_logo from "../icons/app_logo.svg";
import settingIcon from "../assets/icons/lightOutline/Setting.svg";
import { Link } from "react-router-dom";

import activeHome from "../assets/icons/bold/Home.svg";
import inactiveHome from "../assets/icons/lightOutline/Home.svg";
import activeSearch from "../assets/icons/bold/Search.svg";
import inactiveSearch from "../assets/icons/lightOutline/Search.svg";
import activeLibrary from "../assets/icons/bold/Document.svg";
import inactiveLibrary from "../assets/icons/lightOutline/Document.svg";
import MiniPlayer from "./MiniPlayer";

const HeaderDesktop = ({ activeTab, handleTabChange, bookId }) => {
    return (
        <div className="hidden md:flex flex-col sticky top-0 z-50 justify-between items-center px-5 py-4 bg-white mb-1">
            <div className="flex flex-row w-full justify-between">
                <div className="flex items-center space-x-2">
                    <img className="w-8 h-8" src={app_logo} alt="App Logo" />
                    <h1 className="text-xl font-bold text-primary">
                        <span className="text-[#3C78F9]">Esti</span>
                    </h1>
                </div>
                <div className="flex justify-around items-center gap-8">
                    <button onClick={() => handleTabChange("home")} className="flex flex-col items-center">
                        <img src={activeTab === "home" ? activeHome : inactiveHome} alt="Home" className="w-6 h-6 mb-1" />
                        <span className="text-sm">Басты бет</span>
                    </button>

                    <button onClick={() => handleTabChange("search")} className="flex flex-col items-center">
                        <img src={activeTab === "search" ? activeSearch : inactiveSearch} alt="Search" className="w-6 h-6 mb-1" />
                        <span className="text-sm">Іздеу</span>
                    </button>

                    <button onClick={() => handleTabChange("library")} className="flex flex-col items-center">
                        <img src={activeTab === "library" ? activeLibrary : inactiveLibrary} alt="Library" className="w-6 h-6 mb-1" />
                        <span className="text-sm">Менің аудиоларым</span>
                    </button>
                    <Link to={"/settings"} className="flex flex-col items-center">
                        <img
                            src={settingIcon}
                            alt="Settings"
                            className="w-6 h-6 cursor-pointer hover:opacity-80 transition duration-200"
                        />
                        <span className="text-sm">Баптаулар</span>
                    </Link>
                </div>
            </div>
            {bookId && <MiniPlayer bookId={bookId} />}
        </div>
    );
};

export default HeaderDesktop;