import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../config";

const LibrarySection = () => {
    const [userId, setUserId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
            } else {
                setUserId(null);
                setBooks([]);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchUserBooks = async () => {
            if (!userId) {
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                
                const userDocRef = doc(db, "users", userId);
                const userDocSnap = await getDoc(userDocRef);

                if (!userDocSnap.exists()) {
                    console.error("Қолданушы табылмады");
                    setBooks([]);
                    return;
                }

                const savedBookIds = userDocSnap.data().saves || [];

                if (savedBookIds.length === 0) {
                    setBooks([]);
                    return;
                }

                const bookPromises = savedBookIds.map(async (bookId) => {
                    const bookDocRef = doc(db, "books", bookId);
                    const bookDocSnap = await getDoc(bookDocRef);
                    return bookDocSnap.exists() ? { id: bookId, ...bookDocSnap.data() } : null;
                });

                const bookResults = await Promise.all(bookPromises);
                setBooks(bookResults.filter((book) => book !== null));
            } catch (error) {
                console.error("Кітаптарды жүктеу кезінде қате орын алды:", error);
                setBooks([]);
            } finally {
                setLoading(false);
            }
        };

        fetchUserBooks();
    }, [userId]);

    const filteredBooks = books.filter((book) =>
        book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        book.author.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="px-4">
            <h2 className="text-2xl font-semibold mb-4">Менің кітаптарым</h2>
            <div className="relative mb-4">
                <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                <input
                    type="text"
                    placeholder="Кітап немесе авторды іздеу..."
                    className="w-full pl-10 pr-4 py-2 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            {loading ? (
                <p className="text-center text-gray-500">Кітаптар жүктелуде...</p>
            ) : filteredBooks.length > 0 ? (
                <div className="space-y-4">
                    {filteredBooks.map((book) => (
                        <div key={book.id} className="flex items-center space-x-4">
                            <img src={book.imageUrl} alt={book.title} className="w-16 h-20 rounded-md object-cover" />
                            <div>
                                <h3 className="text-lg font-medium">{book.title}</h3>
                                <p className="text-sm text-primary">{book.author}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-center text-gray-500">Кітаптар табылмады.</p>
            )}
        </div>
    );
};

export default LibrarySection;