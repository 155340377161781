import { useState, useEffect, useCallback } from "react";
import useMediaSession from "../components/useMediaSession";
import Listen from "../components/Listen";

const ReadUI = ({ book, audioRef }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [showTimerModal, setShowTimerModal] = useState(false);
    const [showCarModeModal, setShowCarModeModal] = useState(false);
    const [showAudioListModal, setShowAudioListModal] = useState(false);
    const [sleepTime, setSleepTime] = useState(5);

    useEffect(() => {
        audioRef.current = new Audio();
    }, [audioRef]);
    
    useEffect(() => {
        if (!book?.audioUrls?.length) return;
        const audio = audioRef.current;
    
        if (audio.src !== book.audioUrls[currentIndex]) {
            audio.src = book.audioUrls[currentIndex];
            audio.currentTime = 0;
        }
    
        if (isPlaying) {
            audio.play().catch((err) => console.error("Audio play error:", err));
        }
    
        return () => {
            audio.pause();
        };
    }, [currentIndex, book, isPlaying, audioRef]);
    
    useEffect(() => {
        const audio = audioRef.current;
    
        const updateProgress = () => {
            setCurrentTime(audio.currentTime);
            setDuration(audio.duration || 0);
        };
    
        const handleNext = () => {
            setCurrentIndex((prev) => (prev < book.audioUrls.length - 1 ? prev + 1 : 0));
            setIsPlaying(true);
        };
    
        audio.addEventListener("timeupdate", updateProgress);
        audio.addEventListener("ended", handleNext);
    
        return () => {
            audio.removeEventListener("timeupdate", updateProgress);
            audio.removeEventListener("ended", handleNext);
        };
    }, [book, audioRef]);
    
    useEffect(() => {
        if (sleepTime > 0) {
            const timer = setTimeout(() => {
                audioRef.current.pause();
                setIsPlaying(false);
            }, sleepTime * 60000);
            return () => clearTimeout(timer);
        }
    }, [sleepTime, audioRef]);
    
    const togglePlay = useCallback(() => {
        if (!book?.audioUrls?.length) return;
        const audio = audioRef.current;
    
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play().catch((err) => console.error("Audio play error:", err));
        }
        setIsPlaying((prev) => !prev);
    }, [isPlaying, book, audioRef]);
    
    const handleNext = useCallback(() => {
        if (book?.audioUrls?.length) {
            setCurrentIndex((prev) => (prev < book.audioUrls.length - 1 ? prev + 1 : 0));
            setIsPlaying(true);
        }
    }, [book]);
    
    const handlePrev = useCallback(() => {
        if (book?.audioUrls?.length) {
            setCurrentIndex((prev) => (prev > 0 ? prev - 1 : book.audioUrls.length - 1));
            setIsPlaying(true);
        }
    }, [book]);    

    const toggleAudioListModal = () => setShowAudioListModal((prev) => !prev);

    const formatTime = (time) => `${Math.floor(time / 60)}:${String(Math.floor(time % 60)).padStart(2, "0")}`;

    useMediaSession({
        title: book.title,
        artist: book.author,
        albumArt: book.imageUrl,
        isPlaying,
        onPlay: togglePlay,
        onPause: togglePlay,
        onNext: handleNext,
        onPrevious: handlePrev,
    });

    return (
        <>
            {Listen(
                book,
                currentIndex,
                setCurrentIndex,
                setIsPlaying,
                audioRef,
                formatTime,
                currentTime,
                duration,
                setCurrentTime,
                handlePrev,
                togglePlay,
                isPlaying,
                handleNext,
                setShowTimerModal,
                setShowCarModeModal,
                showTimerModal,
                setSleepTime,
                sleepTime,
                showCarModeModal,
                toggleAudioListModal,
                showAudioListModal
            )}
        </>
    );
};

export default ReadUI;