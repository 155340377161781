import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../config";
import { onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem("access_token_esti") || "");

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await user.getIdToken(true);
                setUser(user);
                setToken(token);
                localStorage.setItem("access_token_esti", token);
            } else {
                setUser(null);
                setToken("");
                localStorage.removeItem("access_token_esti");
            }
        });
    
        return () => unsubscribe();
    }, []);    

    return (
        <AuthContext.Provider value={{ user, token }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);