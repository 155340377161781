import React from "react";
import Header from "../components/Header";
import LibrarySection from "../sections/LibrarySection";

const UserUI = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <div className="flex-grow space-y-6">
                <LibrarySection />
            </div>
        </div>
    );
};

export default UserUI;