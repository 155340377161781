import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../screens/Login';
import Register from '../screens/Register';
import Read from '../screens/Read';
import Settings from '../screens/Settings';
import Main from '../screens/Main';

const RoutesComponent = ({ user }) => {
  return (
    <Routes>
      <Route path="/" element={user ? <Main /> : <Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/read/:bookId" element={<Read />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RoutesComponent;