import React from "react";
import activeHome from "../assets/icons/bold/Home.svg";
import inactiveHome from "../assets/icons/lightOutline/Home.svg";
import activeSearch from "../assets/icons/bold/Search.svg";
import inactiveSearch from "../assets/icons/lightOutline/Search.svg";
import activeLibrary from "../assets/icons/bold/Document.svg";
import inactiveLibrary from "../assets/icons/lightOutline/Document.svg";
import MiniPlayer from "./MiniPlayer";

const FooterNav = ({ activeTab, handleTabChange, bookId }) => {
    return (
        <div className="sticky bottom-0 left-0 w-full bg-white drop-shadow-2xl shadow-gray-400 py-4 mt-1 flex md:hidden flex flex-col">
            {bookId && <MiniPlayer bookId={bookId} />}
            <div className="flex justify-around items-center w-full">
                <button onClick={() => handleTabChange("home")} className="flex flex-col items-center">
                    <img src={activeTab === "home" ? activeHome : inactiveHome} alt="Home" className="w-6 h-6 mb-1" />
                    <span className="text-sm">Басты бет</span>
                </button>

                <button onClick={() => handleTabChange("search")} className="flex flex-col items-center">
                    <img src={activeTab === "search" ? activeSearch : inactiveSearch} alt="Search" className="w-6 h-6 mb-1" />
                    <span className="text-sm">Іздеу</span>
                </button>

                <button onClick={() => handleTabChange("library")} className="flex flex-col items-center">
                    <img src={activeTab === "library" ? activeLibrary : inactiveLibrary} alt="Library" className="w-6 h-6 mb-1" />
                    <span className="text-sm">Аудиолар</span>
                </button>
            </div>
        </div>
    );
};

export default FooterNav;