import React, { useState, useEffect } from "react";
import SearchBar from "../components/SearchBar";
import BookItem from "../items/BookItem";

import getSearch from "../api/get/getSearch";

const Explore = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        if (searchQuery.trim() === "") {
            setSearchResults([]);
            return;
        }

        const fetchBooks = async () => {
            try {
                const results = await getSearch(searchQuery);
                setSearchResults(results);
            } catch (error) {
                console.error("Қате:", error);
            }
        };

        const debounceTimer = setTimeout(fetchBooks, 300);
        return () => clearTimeout(debounceTimer);
    }, [searchQuery]);

    return (
        <div className="px-4 py-4">
            <h2 className="text-xl font-bold mb-4">Іздеу</h2>
            <SearchBar onSearch={setSearchQuery} />

            <h3 className="text-lg font-semibold mt-6 mb-3">Іздеу нәтижелері</h3>
            <div className="flex space-x-4 overflow-x-auto">
                {searchResults.length > 0 ? (
                    searchResults.map((book) => (
                        <BookItem 
                            key={book.id} 
                            image={book.imageUrl} 
                            title={book.title} 
                        />
                    ))
                ) : (
                    <p className="text-gray-500">Ештеңе табылған жоқ.</p>
                )}
            </div>
        </div>
    );
};

export default Explore;