import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import app_logo from "../icons/app_logo.svg";
import settingIcon from "../assets/icons/lightOutline/Setting.svg";
import animation_1 from "../assets/animations/1.json";

const Header = () => {
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowAnimation((prev) => !prev);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="sticky top-0 z-50 flex justify-between items-center px-5 py-4 bg-white mb-1 md:hidden">
            <div className="flex items-center space-x-2">
                {showAnimation ? (
                    <Lottie animationData={animation_1} className="w-10 h-10" loop={false} />
                ) : (
                    <img className="w-10 h-10" src={app_logo} alt="App Logo" />
                )}
                <h1 className="text-xl font-bold text-primary">
                    <span className="text-[var(--app-color)]">Esti</span>

                </h1>
            </div>
            <Link to={"/settings"}>
                <img
                    src={settingIcon}
                    alt="Settings"
                    className="w-6 h-6 cursor-pointer hover:opacity-80 transition duration-200"
                />
            </Link>
        </div>
    );
};

export default Header;