import React from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

const DefaultHeader = ({ title, bgColor = "bg-white", textColor = "text-gray-800" }) => {
    const navigate = useNavigate();

    return (
        <div className={`relative sticky top-0 z-50 flex items-center px-5 py-4 ${bgColor} mb-1`}>
            <button onClick={() => navigate('/')} className="p-2">
                <FaChevronLeft className={`text-lg cursor-pointer ${textColor}`} />
            </button>
            <h1 className={`absolute left-1/2 -translate-x-1/2 text-lg font-medium ${textColor}`}>
                {title}
            </h1>
        </div>
    );
};

export default DefaultHeader;