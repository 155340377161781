import PlayIcon from "../assets/icons/bold/Play.svg";
import PauseIcon from "../assets/icons/bold/Pause.svg";
import RightIcon from "../assets/icons/bold/Arrow - Right 2.svg";
import LeftIcon from "../assets/icons/bold/Arrow - Left 2.svg";

import Spinner from "../components/Spinner";

export default function MiniPlayerUI(expanded, toggleExpand, handleTouchStart, handleTouchMove, handleTouchEnd, progress, bookInfo, audioList, currentIndex, handlePlayPause, isPlaying, formatTime, currentTime, handleSeek, duration, handlePrev, handleNext) {
    return <div
        className={`sticky bottom-0 left-0 w-full bg-white transition-all duration-300 
                ${expanded ? "h-full z-50" : "h-20 z-10"} 
                flex flex-col items-center justify-between rounded-t-xl`}
        onClick={toggleExpand}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
    >
        {!expanded && (
            <div className="w-full h-1 bg-gray-300 rounded-full">
                <div className="h-1 bg-blue-500 rounded-full" style={{ width: `${progress}%` }}></div>
            </div>
        )}
        <div className="flex items-center w-full px-4 py-2 cursor-pointer mb-4">
            {bookInfo.imageUrl && (
                <img src={bookInfo.imageUrl} alt="Book Cover" className="w-12 h-12 rounded-lg mr-3" />
            )}
            <div className="flex-1">
                <span className="text-gray-700 text-sm font-medium">{audioList[currentIndex]?.name || <Spinner />}</span>
                {bookInfo.author && (
                    <p className="text-gray-500 text-xs">
                        {expanded ? bookInfo.author : bookInfo.author.length > 22 ? bookInfo.author.slice(0, 19) + '...' : bookInfo.author}
                    </p>
                )}
            </div>
            {!expanded && (
                <button onClick={(e) => { e.stopPropagation(); handlePlayPause(); }} className="p-2">
                    <img src={isPlaying ? PauseIcon : PlayIcon} alt="Play/Pause" className="w-8 h-8" />
                </button>
            )}
        </div>

        {expanded && (
            <div className="flex flex-col items-center w-full px-4 pb-4">
                <div className="flex items-center w-full justify-between mb-2">
                    <span className="text-gray-600 text-xs">{formatTime(currentTime)}</span>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={progress}
                        onChange={handleSeek}
                        className="w-full mx-2" />
                    <span className="text-gray-600 text-xs">{formatTime(duration)}</span>
                </div>
                <div className="flex items-center gap-4">
                    <button onClick={handlePrev} className="p-2">
                        <img src={LeftIcon} alt="Previous" className="w-6 h-6" />
                    </button>
                    <button onClick={handlePlayPause} className="p-2">
                        <img src={isPlaying ? PauseIcon : PlayIcon} alt="Play/Pause" className="w-8 h-8" />
                    </button>
                    <button onClick={handleNext} className="p-2">
                        <img src={RightIcon} alt="Next" className="w-6 h-6" />
                    </button>
                </div>
            </div>
        )}
    </div>;
}