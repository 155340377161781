import React from "react";

const CategoryItem = ({ title }) => {
    return (
        <button
            className="px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 whitespace-nowrap"
            style={{ backgroundColor: "var(--app-color)", color: "#fff", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
        >
            {title}
        </button>
    );
};

export default CategoryItem;