import { useState, useEffect } from 'react';
import { auth } from '../config';
import { onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';

const useAuth = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const cachedUser = localStorage.getItem('user');
        if (cachedUser) {
            setUser(JSON.parse(cachedUser));
            setLoading(false);
        } else {
            setLoading(true);
        }

        const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
            if (user) {
                localStorage.setItem('user', JSON.stringify(user));
            } else {
                localStorage.removeItem('user');
            }
            setUser(user);
            setLoading(false);
        });

        const unsubscribeToken = onIdTokenChanged(auth, (user) => {
            if (!user) {
                localStorage.removeItem('user');
                setUser(null);
            }
        });

        return () => {
            unsubscribeAuth();
            unsubscribeToken();
        };
    }, []);

    return { user, loading };
};

export default useAuth;