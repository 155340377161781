import React from "react";
import { useNavigate } from "react-router-dom";
import PlayIcon from "../assets/icons/bold/Play.svg";
import getCheckIfBookPurchased from "../api/get/getCheckIfBookPurchased";

const BookItem = ({ id, image, title, price, isPaid, onPlay, onBookAccessDenied }) => {
    const navigate = useNavigate();

    const handleNavigate = async () => {
        const hasAccess = await getCheckIfBookPurchased(id);
        if (!isPaid || hasAccess) {
            navigate(`/read/${id}`);
        } else {
            onBookAccessDenied(id);
        }
    };

    return (
        <div
            className="w-full bg-white rounded-lg shadow-lg p-3 flex flex-col items-center cursor-pointer"
            onClick={handleNavigate}
        >
            <div className="relative w-full">
                {price && price > 0 && (
                    <div className="absolute top-2 right-2 bg-yellow-400 text-white p-1 rounded-full shadow-md">
                        <p>₸</p>
                    </div>
                )}
                <img
                    src={image}
                    alt={title}
                    className="h-52 w-full rounded-lg object-cover"
                />
                <button
                    className="absolute bottom-2 right-2 bg-white rounded-full shadow-md p-1"
                    onClick={(e) => {
                        e.stopPropagation();
                        onPlay(id, isPaid);
                    }}
                >
                    <img src={PlayIcon} alt="Play" className="w-9 h-9" />
                </button>
            </div>
            <p className="text-sm font-medium text-center mt-2 line-clamp-2">{title}</p>
        </div>
    );
};

export default BookItem;