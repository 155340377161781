import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import getUser from "../api/get/getUser";

function ProfileEditModal({ closeModal }) {
    const [userData, setUserData] = useState({
        displayName: "",
        email: "",
        imageUrl: "https://via.placeholder.com/100",
    });

    useEffect(() => {
        const fetchUserData = async () => {
            const user = await getUser();
            if (user) {
                setUserData(user);
            }
        };

        fetchUserData();
    }, []);

    return (
        <div className="fixed inset-0 bg-white flex flex-col z-50">
            <div className="flex flex-col items-center p-6 flex-1 overflow-auto">
                <div className="relative">
                    <img
                        src={userData.photoURL || "https://via.placeholder.com/100"}
                        alt="Профиль"
                        className="w-24 h-24 rounded-full border"
                    />
                    <button className="absolute bottom-1 right-1 bg-white p-1 rounded-full shadow-md">
                        <FaEdit className="text-blue-500" />
                    </button>
                </div>
                <div className="mt-6 space-y-4 w-full max-w-md">
                    <div>
                        <label className="block font-semibold">Пайдаланушы аты</label>
                        <input
                            type="text"
                            className="border p-2 w-full rounded-md"
                            defaultValue={userData.displayName}
                        />
                    </div>
                    <div>
                        <label className="block font-semibold">Электрондық пошта</label>
                        <input
                            type="email"
                            className="border p-2 w-full rounded-md"
                            defaultValue={userData.email}
                        />
                    </div>
                </div>
                <button
                    className="mt-6 bg-green-500 text-white px-4 py-2 rounded-lg w-full max-w-md"
                    onClick={closeModal}
                >
                    Жабу
                </button>
            </div>
        </div>
    );
}

export default ProfileEditModal;