import React from 'react';

import UserUI from '../contents/UserUI';

const User = () => {
    return (
        <UserUI />
    );
};

export default User;