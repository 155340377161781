import React, { useEffect, useState } from "react";
import CategoryItem from "../items/CategoryItem";
import getGenres from "../api/get/getGenres";
import Spinner from "../components/Spinner";

const CategoriesSection = () => {
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGenres = async () => {
            try {
                const data = await getGenres();
                if (!data || !Array.isArray(data)) {
                    throw new Error("Жанрлар дұрыс жүктелмеді!");
                }
                setCategories(data);
            } catch (error) {
                console.error("Жанрларды жүктеу қатесі:", error);
                setError("Жанрларды жүктеу мүмкін болмады.");
            }
        };

        fetchGenres();
    }, []);

    return (
        <div className="px-4">
            <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-semibold">Санаттар</h2>
                <button className="text-primary text-sm">Көбірек көру</button>
            </div>

            {error ? (
                <p className="text-red-500 text-sm">{error}</p>
            ) : (
                <div className="flex space-x-2 overflow-x-auto">
                    {categories.length > 0 ? (
                        categories.map((category, index) => (
                            <CategoryItem key={index} title={category.title} />
                        ))
                    ) : (
                        <Spinner />
                    )}
                </div>
            )}
        </div>
    );
};

export default CategoriesSection;