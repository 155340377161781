import React, { useEffect, useState } from "react";
import BookItem from "../items/BookItem";
import getBooks from "../api/get/getBooks";
import Spinner from "../components/Spinner";

const RecommendedSection = ({ onPlay, onBookAccessDenied }) => {
    const [books, setBooks] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBooks = async () => {
            setLoading(true);
            try {
                const bookList = await getBooks("bookAudio");
                setBooks(bookList || []);
            } catch (error) {
                console.error("Ұсынылған кітаптарды алу кезінде қате пайда болды:", error);
            }
            setLoading(false);
        };

        fetchBooks();
    }, []);

    return (
        <div className="px-4">
            <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-semibold">Сізге ұсынылады</h2>
            </div>

            {loading ? (
                <Spinner />
            ) : books.length > 0 ? (
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {books.slice(0, showAll ? books.length : 8).map((book) => (
                        <BookItem
                            key={book.id}
                            id={book.id}
                            image={book.imageUrl}
                            title={book.title}
                            price={book.price}
                            isPaid={book.isPaid}
                            onPlay={onPlay}
                            onBookAccessDenied={onBookAccessDenied}
                        />
                    ))}
                </div>
            ) : (
                <p className="text-gray-500">Ұсынылған аудиокітаптар жоқ.</p>
            )}

            {books.length > 8 && (
                <div className="flex justify-center mt-4">
                    <button
                        onClick={() => setShowAll(!showAll)}
                        className="px-4 py-2 text-white rounded-lg"
                        style={{ backgroundColor: "var(--app-color)", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
                    >
                        {showAll ? "Жасыру" : "Көбірек көрсету"}
                    </button>
                </div>
            )}
        </div>
    );
};

export default RecommendedSection;