import { useRef } from "react";

const SleepTimer = ({ sleepTime, setSleepTime }) => {
    const circleRef = useRef(null);
    const isDragging = useRef(false);

    const radius = 40;
    const circumference = 2 * Math.PI * radius;

    const times = Array.from({ length: 12 }, (_, i) => (i + 1) * 10);

    const getCoords = (angle, offset = 0) => {
        const radians = (angle - 90) * (Math.PI / 180);
        const x = 60 + (radius + offset) * Math.cos(radians);
        const y = 60 + (radius + offset) * Math.sin(radians);
        return { x, y };
    };

    const handleDrag = (e) => {
        if (!isDragging.current) return;

        const { clientX, clientY } = e.touches ? e.touches[0] : e;

        const rect = circleRef.current.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;

        const dx = clientX - centerX;
        const dy = clientY - centerY;

        let angle = Math.atan2(dy, dx) * (180 / Math.PI) + 90;
        if (angle < 0) angle += 360;

        const newTime = Math.round((angle / 360) * 120);
        setSleepTime(Math.min(120, Math.max(1, newTime)));
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        isDragging.current = true;
        document.addEventListener("mousemove", handleDrag);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleTouchStart = (e) => {
        e.preventDefault();
        isDragging.current = true;
        document.addEventListener("touchmove", handleDrag);
        document.addEventListener("touchend", handleMouseUp);
    };

    const handleMouseUp = () => {
        isDragging.current = false;
        document.removeEventListener("mousemove", handleDrag);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("touchmove", handleDrag);
        document.removeEventListener("touchend", handleMouseUp);
    };

    const { x, y } = getCoords((sleepTime / 120) * 360);

    return (
        <div className="flex flex-col items-center gap-4">
            <h2 className="text-2xl font-bold text-center">Ұйқы таймерін орнатыңыз</h2>

            <div className="relative w-64 h-64 flex items-center justify-center">
                <svg ref={circleRef} className="w-full h-full" viewBox="0 0 120 120">
                    <circle cx="60" cy="60" r={radius} stroke="#ddd" strokeWidth="5" fill="none" />
                    <circle
                        cx="60"
                        cy="60"
                        r={radius}
                        stroke="blue"
                        strokeWidth="5"
                        fill="none"
                        strokeDasharray={circumference}
                        strokeDashoffset={circumference - (sleepTime / 120) * circumference}
                        strokeLinecap="round"
                        transform="rotate(-90 60 60)"
                    />
                    <circle
                        cx={x}
                        cy={y}
                        r="5"
                        fill="blue"
                        className="cursor-pointer"
                        onMouseDown={handleMouseDown}
                        onTouchStart={handleTouchStart}
                    />
                    {times.map((time) => {
                        const angle = (time / 120) * 360;
                        const { x, y } = getCoords(angle, 15);
                        return (
                            <text
                                key={time}
                                x={x}
                                y={y}
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fontSize="8"
                                fill="black"
                            >
                                {time}
                            </text>
                        );
                    })}
                </svg>
                <span className="absolute flex items-center justify-center text-xl font-bold h-min w-min">
                    {sleepTime} мин.
                </span>
            </div>
        </div>
    );
};

export default SleepTimer;