import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { FaChevronLeft } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import animation_1 from "../assets/animations/3.json";

const DetailHeader = ({ title, onBack, textColor = "text-white", bgColor = "bg-transparent" }) => {
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowAnimation((prev) => !prev);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={`sticky top-0 z-50 flex justify-between items-center px-5 py-4 ${bgColor}`}>
            <button onClick={onBack}>
                <FaChevronLeft className={`text-lg cursor-pointer ${textColor}`} />
            </button>

            <div className="flex items-center space-x-2">
                {showAnimation ? (
                    <Lottie animationData={animation_1} className="w-32 h-12" loop={false} />
                ) : (
                    <h1 className={`text-base font-medium truncate flex-1 px-2 w-62 h-12 flex items-center justify-center ${textColor}`}>
                        {title}
                    </h1>
                )}
                {/* <Lottie animationData={animation_1} className="w-32 h-16" loop={false} /> */}
            </div>

            <HiDotsHorizontal className={`text-lg cursor-pointer ${textColor}`} />
        </div>
    );
};

export default DetailHeader;