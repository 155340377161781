const API_URL = process.env.REACT_APP_API_URL_PROD;

const postPayment = async (book_id, book_price) => {
    try {
        const token = localStorage.getItem("access_token_esti");

        const response = await fetch(`${API_URL}/payments/payment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify({
                book_id,
                book_price,
            }),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to make payment:", error);
        return null;
    }
};

export default postPayment;