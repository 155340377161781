import React from "react";
import Header from "../components/Header";
import Explore from "../components/Explore";

const BooksUI = () => {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <Header />
            <div className="flex-grow overflow-auto">
                <Explore />
            </div>
        </div>
    );
};

export default BooksUI;