import { useEffect } from "react";

const useAudioProgress = (audioRef, bookId, currentIndex) => {
    useEffect(() => {
        if (!bookId) return;

        const key = `audioTime-${bookId}-${currentIndex}`;
        const savedTime = localStorage.getItem(key);

        const audio = audioRef.current;

        const handleCanPlay = () => {
            if (savedTime && !isNaN(savedTime)) {
                audio.currentTime = parseFloat(savedTime);
            }
        };

        audio.addEventListener("loadedmetadata", handleCanPlay);

        const saveProgress = () => {
            localStorage.setItem(key, audio.currentTime);
        };

        audio.addEventListener("timeupdate", saveProgress);

        return () => {
            audio.removeEventListener("loadedmetadata", handleCanPlay);
            audio.removeEventListener("timeupdate", saveProgress);
        };
    }, [audioRef, bookId, currentIndex]);
};

export default useAudioProgress;