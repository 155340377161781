import React, { useState } from "react";
import ProfileEditModal from "../modals/ProfileEditModal";
import SettingsUI from "../contents/SettingsUI";
import DefaultHeader from "../components/DefaultHeader";

function Settings() {
    const [isEditing, setIsEditing] = useState(false);

    return (
        <div className="max-w-3xl mx-auto">
            <DefaultHeader title={"Баптаулар"} onBack={"/"} textColor="text-black"/>
            <SettingsUI onOpen={setIsEditing}/>
            {isEditing && <ProfileEditModal closeModal={() => setIsEditing(false)} />}
        </div>
    );
}

export default Settings;