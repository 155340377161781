import React from "react";
import { FaPlay, FaPause, FaBackward, FaForward, FaCar } from "react-icons/fa";
import { IoTimerOutline } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import { TbRewindForward15, TbRewindBackward15 } from "react-icons/tb";
import { CiCircleList } from "react-icons/ci";
import SleepTimer from "./SleepTimer";

export default function Listen(book, currentIndex, setCurrentIndex, setIsPlaying, audioRef, formatTime, currentTime, duration, setCurrentTime, handlePrev, togglePlay, isPlaying, handleNext, setShowTimerModal, setShowCarModeModal, showTimerModal, setSleepTime, sleepTime, showCarModeModal, toggleAudioListModal, showAudioListModal) {
    return <div className="flex flex-col items-center text-center mt-10 space-y-4">
        <img
            src={book.imageUrl}
            alt={book.title}
            className="w-auto h-[350px] rounded-[30px] mx-auto"
        />
        <div className="absolute top-1/2 h-1/2 w-full px-4 flex flex-col justify-evenly" style={{ margin: 0, alignItems: 'center' }}>
            <div className="text-center h-20 flex flex-col justify-center overflow-hidden">
                <AnimatePresence mode="popLayout">
                    {book.audioFileNames
                        .map((chapter, index) => ({
                            name: chapter,
                            position: index - currentIndex,
                        }))
                        .filter((item) => Math.abs(item.position) <= 1)
                        .map((item) => (
                            <motion.p
                                key={item.name}
                                initial={{ opacity: 0, y: item.position * 30 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -item.position * 30 }}
                                transition={{ duration: 0.3 }}
                                className={`text-lg cursor-pointer transition-all duration-300 ${item.position === 0 ? "font-bold text-black text-xl" : "text-gray-500 opacity-70"}`}
                                onClick={() => {
                                    setCurrentIndex(currentIndex + item.position);
                                    setIsPlaying(true);
                                    audioRef.current.play();
                                }}
                            >
                                {item.name}
                            </motion.p>
                        ))}
                </AnimatePresence>
            </div>
            <button className="text-[var(--app-color)]" onClick={toggleAudioListModal}>
                <CiCircleList size={24} />
            </button>
            <div className="w-full flex items-center justify-between px-4 text-gray-500">
                <span>{formatTime(currentTime)}</span>
                <input
                    type="range"
                    min="0"
                    max={duration || 1}
                    value={currentTime}
                    onChange={(e) => {
                        const newTime = Number(e.target.value);
                        audioRef.current.currentTime = newTime;
                        setCurrentTime(newTime);
                    }}
                    className="w-full mx-4 h-1 bg-gray-300 rounded overflow-hidden cursor-pointer" />
                <span>{formatTime(duration)}</span>
            </div>
            <div className="flex items-center justify-center space-x-6 mt-4">
                <button className="text-[var(--app-color)]" onClick={() => audioRef.current.currentTime -= 15}>
                    <TbRewindBackward15 size={24} />
                </button>
                <button className="text-[var(--app-color)]" onClick={handlePrev}><FaBackward size={24} /></button>
                <button className="bg-[var(--app-color)] text-white p-4 rounded-full shadow-lg" onClick={togglePlay}>
                    {isPlaying ? <FaPause size={24} /> : <FaPlay size={24} />}
                </button>
                <button className="text-[var(--app-color)]" onClick={handleNext}><FaForward size={24} /></button>
                <button className="text-[var(--app-color)]" onClick={() => audioRef.current.currentTime += 15}>
                    <TbRewindForward15 size={24} />
                </button>
            </div>
            <div className="flex justify-between w-full text-xs text-gray-600 mb-4 px-6">
                <div className="flex flex-col items-center" onClick={() => setShowTimerModal(true)}>
                    <IoTimerOutline size={24} className="text-[var(--app-color)]" />
                </div>
                <div className="flex flex-col items-center" onClick={() => setShowCarModeModal(true)}>
                    <FaCar size={24} className="text-[var(--app-color)]" />
                </div>
            </div>
        </div>
        <AnimatePresence>
            {showTimerModal && (
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50 w-full h-full"
                >
                    <div className="bg-white p-5 rounded-lg shadow-lg w-full h-full flex flex-col items-center justify-center space-y-6 relative">
                        <button
                            onClick={() => setShowTimerModal(false)}
                            className="absolute top-4 right-4 text-blue-500 text-lg"
                        >
                            Жабу
                        </button>
                        <button
                            onClick={() => {
                                setSleepTime(0);
                                setShowTimerModal(false);
                            }}
                            className="absolute top-4 left-4 text-blue-500 text-lg"
                            style={{ margin: 0 }}
                        >
                            Болдырмау
                        </button>
                        <SleepTimer sleepTime={sleepTime} setSleepTime={setSleepTime} />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
        <AnimatePresence>
            {showCarModeModal && (
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50 w-full h-full"
                >
                    <div className="bg-white p-5 rounded-lg shadow-lg flex flex-col w-full h-full relative">
                        <button
                            onClick={() => setShowCarModeModal(false)}
                            className="absolute top-4 right-4 text-blue-500 text-lg"
                        >
                            Жабу
                        </button>
                        <div className="flex-1 flex flex-col items-center justify-center border-b pb-4">
                            <img
                                src={book.imageUrl}
                                alt={book.title}
                                className="w-auto h-[350px] rounded-[30px] mx-auto"
                            />
                            <h2 className="text-2xl font-bold text-center mt-2">{book.audioFileNames[currentIndex]}</h2>
                        </div>
                        <div className="flex-1 flex justify-center items-center relative">
                            <div className="absolute left-10">
                                <button onClick={() => audioRef.current.currentTime -= 15}>
                                    <TbRewindBackward15 size={32} />
                                </button>
                            </div>
                            <div className="absolute right-10">
                                <button onClick={() => audioRef.current.currentTime += 15}>
                                    <TbRewindForward15 size={32} />
                                </button>
                            </div>
                            <button
                                onClick={togglePlay}
                                className="p-4 bg-gray-200 rounded-full flex items-center justify-center"
                                style={{ width: 54, height: 54 }}
                            >
                                {isPlaying ? <FaPause size={54} /> : <FaPlay size={54} />}
                            </button>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
        <AnimatePresence>
            {showAudioListModal && (
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50 w-full h-full"
                >
                    <div className="bg-white p-5 rounded-lg shadow-lg flex flex-col w-full h-full relative">
                        <button
                            onClick={() => toggleAudioListModal()}
                            className="absolute top-4 right-4 text-blue-500 text-lg"
                        >
                            Жабу
                        </button>
                        <div className="mt-6 overflow-y-auto">
                            <AnimatePresence mode="popLayout">
                                {book.audioFileNames
                                    .map((chapter, index) => ({
                                        name: chapter,
                                        position: index - currentIndex,
                                    }))
                                    .map((item) => (
                                        <motion.p
                                            key={item.name}
                                            initial={{ opacity: 0, y: 10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            transition={{ duration: 0.3 }}
                                            className={`text-lg cursor-pointer transition-all duration-300 
                                            ${item.position === 0 ? "font-bold text-black text-xl" : "text-gray-500 opacity-70"}`}
                                            onClick={() => {
                                                setCurrentIndex(currentIndex + item.position);
                                                setIsPlaying(true);
                                                audioRef.current.play();
                                            }}
                                        >
                                            {item.name}
                                        </motion.p>
                                    ))}
                            </AnimatePresence>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    </div>;
}