import { useEffect } from "react";

const useMediaSession = ({ title, artist, albumArt, isPlaying, onPlay, onPause, onNext, onPrevious }) => {
    useEffect(() => {
        if ("mediaSession" in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title,
                artist,
                artwork: albumArt ? [{ src: albumArt, sizes: "512x512", type: "image/png" }] : [],
            });

            navigator.mediaSession.setActionHandler("play", onPlay);
            navigator.mediaSession.setActionHandler("pause", onPause);
            navigator.mediaSession.setActionHandler("nexttrack", onNext);
            navigator.mediaSession.setActionHandler("previoustrack", onPrevious);
        }
    }, [title, artist, albumArt, isPlaying, onPlay, onPause, onNext, onPrevious]);
};

export default useMediaSession;