import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReadUI from "../contents/ReadUI";
import DetailHeader from "../components/DetailHeader";
import getListen from "../api/get/getListen";

const Read = () => {
    const { bookId } = useParams();
    const navigate = useNavigate();
    const [book, setBook] = useState(null);
    const [loading, setLoading] = useState(true);
    const audioRef = useRef(null);

    useEffect(() => {
        const fetchBook = async () => {
            if (!bookId) return;
            setLoading(true);
            try {
                const bookData = await getListen(bookId);
                if (bookData) {
                    setBook(bookData);
                } else {
                    console.log("Кітап табылмады!");
                }
            } catch (error) {
                console.error("Қате:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBook();
    }, [bookId]);

    const handleBack = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        navigate("/");
    };

    if (loading) return <p className="text-center text-gray-500 mt-10"></p>;
    if (!book) return <p className="text-center text-red-500 mt-10">Кітап табылмады!</p>;

    return (
        <div className="flex flex-col min-h-screen">
            <DetailHeader title={book.title} onBack={handleBack} textColor="text-[var(--app-color)]"/>
            <div className="flex-grow space-y-6">
                <ReadUI book={book} audioRef={audioRef} />
            </div>
        </div>
    );
};

export default Read;