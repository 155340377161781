import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import RoutesComponent from './routes/RoutesComponent';
import useAuth from './hooks/useAuth';
import useUserStatus from './hooks/useUserStatus';

import './App.css';
import './scroller.css';

import { AuthProvider } from "./context/AuthContext";

import SplashScreen from './components/SplashScreen';

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const App = () => {
  const { user, loading } = useAuth();
  useUserStatus(user);

  const [showSplash, setShowSplash] = useState(isIOS);

  useEffect(() => {
    if (isIOS) {
      console.log("show")
      setTimeout(() => setShowSplash(false), 2000);
    }
  }, []);

  if (loading || showSplash) {
    return <SplashScreen />;
  }

  return (
    <AuthProvider>
      <Router>
        <RoutesComponent user={user} />
      </Router>
    </AuthProvider>
  );
};

export default App;