const API_URL = process.env.REACT_APP_API_URL_PROD;

const getListen = async (bookId) => {
    const token = localStorage.getItem("access_token_esti");

    if (!token) {
        console.error("Қолданушы тіркелмеген");
        return null;
    }

    try {
        const response = await fetch(`${API_URL}/listen/${bookId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Аудиокітапты алу кезінде қате:", error);
        return null;
    }
};

export default getListen;