import { useEffect, useState, useRef } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config";

import useMediaSession from "./useMediaSession";
import useAudioProgress from "../hooks/useAudioProgress";

import MiniPlayerUI from "../ui/MiniPlayerUI";

const MiniPlayer = ({ bookId }) => {
    const [audioList, setAudioList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [bookInfo, setBookInfo] = useState({ imageUrl: "", author: "" });
    const [expanded, setExpanded] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(new Audio());

    const [touchStartX, setTouchStartX] = useState(null);
    const [touchEndX, setTouchEndX] = useState(null);

    useAudioProgress(audioRef, bookId, currentIndex);

    useEffect(() => {
        const fetchAudioData = async () => {
            if (bookId) {
                try {
                    const bookDoc = await getDoc(doc(db, "books", bookId));
                    if (bookDoc.exists()) {
                        const { audioUrls, audioFileNames, imageUrl, author } = bookDoc.data();
                        if (audioUrls && audioFileNames) {
                            setAudioList(audioUrls.map((url, index) => ({
                                name: audioFileNames[index],
                                url,
                            })));
                        }
                        setBookInfo({ imageUrl, author });
                    }
                } catch (error) {
                    console.error("Error fetching audio data:", error);
                }
            }
        };

        fetchAudioData();
    }, [bookId]);

    useEffect(() => {
        if (audioList.length > 0) {
            const audio = audioRef.current;
            const wasPlaying = !audio.paused;
            audio.src = audioList[currentIndex]?.url;

            if (wasPlaying) {
                audio.play();
            }
        }
    }, [currentIndex, audioList]);

    useEffect(() => {
        const audioElement = audioRef.current;

        const updateProgress = () => {
            if (audioElement.duration) {
                setCurrentTime(audioElement.currentTime);
                setDuration(audioElement.duration);
                setProgress((audioElement.currentTime / audioElement.duration) * 100);
            }
        };

        audioElement.addEventListener("timeupdate", updateProgress);
        return () => {
            audioElement.removeEventListener("timeupdate", updateProgress);
        };
    }, []);

    const handlePlayPause = () => {
        if (audioList.length > 0) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex < audioList.length - 1 ? prevIndex + 1 : 0
        );
        setIsPlaying(true);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : audioList.length - 1
        );
        setIsPlaying(true);
    };

    const toggleExpand = () => {
        setExpanded((prev) => !prev);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    const handleSeek = (e) => {
        const newTime = (e.target.value / 100) * duration;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const handleTouchStart = (e) => {
        setTouchStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEndX(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStartX || !touchEndX || expanded) return;

        const deltaX = touchStartX - touchEndX;
        if (Math.abs(deltaX) > 50) {
            if (deltaX > 0) {
                handleNext();
            } else {
                handlePrev();
            }
        }

        setTouchStartX(null);
        setTouchEndX(null);
    };

    useMediaSession({
        title: audioList[currentIndex]?.name || "Аудио қолжетімсіз",
        artist: bookInfo.author || "Белгісіз автор",
        albumArt: bookInfo.imageUrl || "",
        isPlaying,
        onPlay: handlePlayPause,
        onPause: handlePlayPause,
        onNext: handleNext,
        onPrevious: handlePrev,
    });

    return (
        MiniPlayerUI(expanded, toggleExpand, handleTouchStart, handleTouchMove, handleTouchEnd, progress, bookInfo, audioList, currentIndex, handlePlayPause, isPlaying, formatTime, currentTime, handleSeek, duration, handlePrev, handleNext)
    );
};

export default MiniPlayer;