const API_URL = process.env.REACT_APP_API_URL_PROD;

const getUser = async () => {
    const token = localStorage.getItem("access_token_esti");

    if (!token) {
        console.error("Қолданушы тіркелмеген");
        return null;
    }

    try {
        const response = await fetch(`${API_URL}/users/`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const userData = await response.json();
        return userData;
    } catch (error) {
        console.error("Қолданушы деректерін алу қатесі:", error);
        return null;
    }
};

export default getUser;