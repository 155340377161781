import React, { useState } from 'react';
import HomeUI from '../contents/HomeUI';
import BuyModal from '../modals/BuyModal';

const Home = ({ onPlay }) => {
    const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
    const [selectedBook, setSelectedBook] = useState(null);

    const handleBookAccessDenied = (id) => {
        setSelectedBook(id);
        setPaymentModalOpen(true);
    };

    return (
        <>
            <HomeUI onPlay={onPlay} onBookAccessDenied={handleBookAccessDenied} />
            {isPaymentModalOpen && (
                <BuyModal
                    isOpen={isPaymentModalOpen}
                    bookId={selectedBook}
                    onClose={() => setPaymentModalOpen(false)}
                />
            )}
        </>
    );
};

export default Home;