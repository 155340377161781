import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../config";
import getUser from "../api/get/getUser";

function SettingsUI({ onOpen }) {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        name: "",
        imageUrl: "https://via.placeholder.com/50",
    });

    useEffect(() => {
        const fetchUserData = async () => {
            const user = await getUser();
            if (user) {
                setUserData(user);
            }
        };

        fetchUserData();
    }, []);

    const handleLogout = async () => {
        try {
            await auth.signOut();
            localStorage.clear();
            sessionStorage.clear();
            navigate("/");
        } catch (error) {
            console.error("Шығу кезінде қате:", error);
        }
    };

    return (
        <div className="m-6">
            <div className="flex items-center gap-4 pb-6 border-b">
                <img
                    src={userData.photoURL || "https://via.placeholder.com/50"}
                    alt="Пайдаланушы"
                    className="w-12 h-12 rounded-full"
                />
                <div>
                    <h2 className="font-semibold text-lg">{userData.displayName || "Аты жоқ"}</h2>
                    <button onClick={() => onOpen(true)} className="text-blue-500 text-sm">
                        Профильді қарау
                    </button>
                </div>
            </div>
            <ul className="mt-4 space-y-4">
                <li className="cursor-pointer hover:text-blue-500">Хабарландырулар</li>
                <li className="cursor-pointer hover:text-blue-500">Деректер мен сақтау</li>
                <li className="cursor-pointer hover:text-blue-500">Жазылым</li>
                <li className="cursor-pointer hover:text-blue-500">Аудиокітаптар туралы</li>
            </ul>
            <button
                onClick={handleLogout}
                className="mt-6 w-full border border-red-500 text-red-500 py-2 rounded-lg hover:bg-red-100"
            >
                Шығу
            </button>
        </div>
    );
}

export default SettingsUI;