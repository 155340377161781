import React from 'react';
import Lottie from 'lottie-react';
import '../style/SplashScreen.css';
import animation_1 from '../assets/animations/1.json';

const SplashScreen = () => {
    return (
        <div className="splash-screen">
            <Lottie animationData={animation_1} className="splash-animation" loop={true} />
        </div>
    );
};

export default SplashScreen;