import React, { useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../config";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

import app_logo from "../icons/app_logo.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleAuth = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/home");
    } catch (err) {
      switch (err.code) {
        case "auth/user-not-found":
          setErrorMessage("Осы электрондық поштасы бар пайдаланушы табылмады.");
          break;
        case "auth/wrong-password":
          setErrorMessage("Қате құпия сөз.");
          break;
        default:
          setErrorMessage("Қате орын алды. Қайталап көріңіз.");
      }
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        lastLogin: new Date(),
      }, { merge: true });
      navigate("/home");
    } catch (err) {
      console.error("Google кіру қатесі:", err);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <div className="w-full max-w-md p-8 rounded-2xl">
        <div className="flex justify-center mb-6">
          <img src={app_logo} alt="app_logo" />
        </div>

        <h2 className="text-2xl font-bold text-center text-gray-800">Аккаунтқа кіру</h2>

        {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}

        <form onSubmit={handleAuth}>
          <div className="mt-6">
            <input
              type="email"
              placeholder="Электрондық пошта"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#3C78F9] mb-4"
              required
            />
            <input
              type="password"
              placeholder="Құпия сөз"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#3C78F9]"
              required
            />
          </div>

          <div className="flex justify-between items-center mt-4">
            <a href="/" className="text-sm text-[#E68F5A]">Құпия сөзді ұмыттыңыз ба?</a>
          </div>

          <button
            type="submit"
            className="w-full mt-6 bg-[#3C78F9] text-white p-3 rounded-lg font-bold hover:bg-[#2A5ECB]"
          >
            Кіру
          </button>
        </form>

        <div className="text-center mt-6 text-gray-600">Немесе Google арқылы кіріңіз</div>
        <div className="flex justify-center mt-4">
          <button
            onClick={handleGoogleSignIn}
            className="flex items-center gap-2 px-6 py-3 bg-[#E68F5A] text-white rounded-lg font-bold shadow-md hover:bg-[#D1774A]"
          >
            <FaGoogle size={20} /> Google арқылы кіру
          </button>
        </div>

        <div className="text-center mt-6 text-gray-600">
          Аккаунтыңыз жоқ па? <a href="/register" className="text-[#E68F5A] font-bold">Тіркелу</a>
        </div>
      </div>
    </div>
  );
};

export default Login;