const API_URL = process.env.REACT_APP_API_URL_PROD;

const getSearch = async (query) => {
    if (!query.trim()) {
        return [];
    }

    try {
        const response = await fetch(`${API_URL}/search/?query=${query}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const books = await response.json();
        return books;
    } catch (error) {
        console.error("Іздеу қатесі:", error);
        return [];
    }
};

export default getSearch;