import React, { useEffect, useState } from "react";
import BookItem from "../items/BookItem";
import getBooks from "../api/get/getBooks";

import Spinner from "../components/Spinner";

const BestSellerSection = ({ onPlay }) => {
    const [bestSellers, setBestSellers] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBestSellers = async () => {
            setLoading(true);
            try {
                const books = await getBooks("songAudio");
                setBestSellers(books || []);
            } catch (error) {
                console.error("Error fetching bestsellers:", error);
            }
            setLoading(false);
        };

        fetchBestSellers();
    }, []);

    return (
        <div className="px-4">
            <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-semibold">Жырлар</h2>
            </div>

            {loading ? (
                <Spinner />
            ) : bestSellers.length > 0 ? (
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {bestSellers.slice(0, showAll ? bestSellers.length : 8).map((book) => (
                        <BookItem
                            key={book.id}
                            id={book.id}
                            image={book.imageUrl}
                            title={book.title}
                            onPlay={onPlay}
                        />
                    ))}
                </div>
            ) : (
                <p className="text-gray-500">Аудиокітаптар жоқ.</p>
            )}

            {bestSellers.length > 8 && (
                <div className="flex justify-center mt-4">
                    <button
                        onClick={() => setShowAll(!showAll)}
                        className="px-4 py-2 text-white rounded-lg"
                        style={{ backgroundColor: "var(--app-color)", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
                    >
                        {showAll ? "Жасыру" : "Көбірек көрсету"}
                    </button>
                </div>
            )}
        </div>
    );
};

export default BestSellerSection;