import { useEffect } from 'react';
import { ref, set, onDisconnect } from 'firebase/database';
import { realtimeDb } from '../config';

const useUserStatus = (user) => {
  useEffect(() => {
    if (!user) return;

    const userStatusRef = ref(realtimeDb, `status/${user.uid}`);
    const isOnline = { state: 'online', last_changed: Date.now() };
    const isOffline = { state: 'offline', last_changed: Date.now() };

    set(userStatusRef, isOnline);
    onDisconnect(userStatusRef).set(isOffline);
  }, [user]);
};

export default useUserStatus;